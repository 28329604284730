.helpContainer {
  position: fixed;
  width: 330px;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1000;
  left: calc(50% - 165px);
  margin-left: auto;
  margin-right: auto;
}

.hideContainer {
  transition: bottom 1s ease-in-out;
  bottom: -400px;
}

.showContainer {
  transition: bottom 1s ease-in-out;
  bottom: 0px;
}

.contentContainer {
  padding: 10px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  /* transition:  max-width 1s ease-in-out; */
}

.listPhones {
  padding: 0 20px;
  margin: 0 0 20px 0;
}

.listPhones > li {
  margin: 10px 0;
}

.headerContainer {
  width: 100%;
  border: none;
  background: #1c5781;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headerContainer:focus {
  outline: none;
}

.headerContainerParagraph {
  color: white;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  width: 100%;
}

.minimizeTrigger {
  width: 15px;
  height: 3px;
  background: white;
  margin-left: 15px;
  position: absolute;
}

@media only screen and (min-width: 700px) {
  .helpContainer {
    right: 48px;
    left: unset;
  }
}
