.maskedInput {
    border: none;
    border-bottom: 1px solid black;
    padding: 0 6px;
    border-color: #B2B0B0;
    background-color: #FDE590;
    border-radius: 11px;
    opacity: .8;
}

.maskedInput:focus {
    opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}