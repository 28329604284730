.easyProposalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  padding-top: 0px;
}
.ratingAndFavoriteContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.favoriteContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.favoriteContainer p {
  font-size: 11px;
  color: #ff5f5f;
  margin-bottom: 3px;
}

.ratingContainer {
  /* flex-grow: 1; */
}

.ratingContainer p {
  font-size: 11px;
  color: #ffa43e;
  margin-bottom: 6px;
}

.proposalContainerList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 100px;
}

.proposalCard {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
  margin: 0px 0px 15px 0px;
}

.proposalCard:active {
  opacity: 0.5;
}

.proposalInfoContainer {
  /* padding-top: 15px;
  padding-bottom: 15px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-content: center;
  justify-content: center; */
  /* align-items: center; */
  padding: 10px 10px 10px 0px;
}

.textCenter {
  display: flex;
  justify-content: center;
  color: #256992;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}

/* .proposalStatus {
  width: 10px;
  margin-right: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: red;
} */

.greyCellText {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  color: #a5a1a1;
  margin: 5px 10px 5px 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  /* white-space: nowrap; */
}

.greyCellTextInfo {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  color: #a5a1a1;
  margin: 0px 10px 4px 0px;
  /* white-space: nowrap; */
}

.boxOrder {
  padding: 13px 0 0 0;
}

.boxOrder-boxLegends {
  display: flex;
  flex-direction: row;
}

.boxOrder-legends {
  display: flex;
  align-items: center;
  font-size: 11px;
}

.boxOrder-boxLegends-selectInputRadio {
  width: 15px !important;
  margin: 0 8px 0 0 !important;
}

.greyCellItemText {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  color: #a5a1a1;
  margin: 0px 10px 0px 0px;
  /* white-space: nowrap; */
}

.cellFooterInfoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 8px;
}

.cellInfoItens {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.cellInfoParagraphs {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cellIcons {
  margin-right: 5px;
}

.infoCellItens {
  margin-right: 10px;
}

.searchBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 60px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* padding: 5px; */
  /* margin-bottom: 60px; */
  /* box-shadow: 0px 3px 6px #00000029; */
}

.searchBar input {
  width: 100%;
  height: 35px;
  border: 2px solid #d0d0d0;
  padding: 5px 0px 5px 10px;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
}

.searchBar input:focus {
  outline: none;
  border: 2px solid #256992;
}

.space-between {
  margin-top: 8px;
  margin-bottom: 8px;
}

/* .filterDesktopContainer {
  padding: 5px 0px 20px 0px;

  flex-direction: row;
  
  width: 100%;
  justify-content: center;
  display: none;

} */

/* .filterButton {
  background: white;
  border: 2px solid #1592e6;
  margin-right: 20px;
  padding: 10px;
  border-radius: 20px;
  color: #1592e6;
  cursor: pointer;
  font-weight: 500;
} */

.filterButton:focus {
  outline: none;
}

.filterButton:active {
  opacity: 0.5;
}

.filterContainer {
  width: 100%;
}

.filterContainer form {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.filterContainer .greyCellText {
  margin-bottom: 5px;
}

.selectorOptionText {
  color: #6f6f6f;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  padding-left: 5px;
}

.filterContainer select {
  border: 1px solid #bfbfbf;
  height: 35px;
  font-size: 11px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
}

.filterContainer form select {
  color: #6f6f6f;
  padding-left: 5px;
}

.filterContainer form select:focus {
  outline: none;
}

@media only screen and (min-width: 830px) {
  /* .filterDesktopContainer {
    display: flex;
  } */

  /* .filterMobileContainer {
    display: none;
  } */

  form {
    width: 329px;
  }

  .proposalCard {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: calc(100% / 2 - 14px);
    margin-bottom: 15px;
    cursor: pointer;
    text-decoration: none;
    margin: 0px 7px 15px 7px;
  }
}

@media only screen and (min-width: 1400px) {
  .proposalCard {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: calc(100% / 3 - 14px);
    margin-bottom: 15px;
    cursor: pointer;
    text-decoration: none;
    /* margin: 0px 7px 15px 7px; */
  }

  .searchBar input {
    max-width: 1280px;
  }

  .cellinfoParagraphs {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
