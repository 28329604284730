.react-tagsinput-input {
  width: auto;
  height: 32px;
  margin: 0;
  padding: 5px;
  color: #777;
  font: 400 0.875rem "Eurostile";
  background: transparent;
  border: 0;
  outline: none;
}

.react-tagsinput-tag {
  position: relative;
  background-color: #f7f7f7;
  border-radius: 13px;
  border: 1px solid #e6e6e6;
  color: #757575;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: -9px;
  margin-right: 5px;
  padding: 5px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70px;
}

.react-tagsinput-remove {
  position: absolute;
  top: 50%;
  right: 2px;
  color: #505050;
  cursor: pointer;
  font-weight: 700;
  font-size: 1rem;
  transform: translateY(-50%);
}
