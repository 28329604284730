.proposalDescriptionContainer {
  overflow-x: hidden;
  overflow-y: auto;
}

.proposalDescriptionMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
  /* padding-left: 20px;
  padding-right: 20px; */
  padding-top: 20px;
}

.backButton {
  color: #256992;
  box-shadow: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
}

.backButton:active {
  opacity: 0.5;
}

.backButton img {
  margin-right: 10px;
}

.descriptionHeader {
  display: flex;
  flex-direction: column;
}

.descriptionTextTitle {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 0px;
  color: #707070;
  padding: 0px 20px 0px 20px;
}

.descriptionTextTitleGray {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 0px;
  color:  #9f9f9f;
  padding: 0px 20px 0px 20px;
}

.descriptionTextBold {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 0px;
  color: #707070;
  padding: 0px 0px 0px 0px;
}

.headerTopics {
  display: flex;
  flex-direction: column;
}

.descriptiontext {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  padding: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 0px;
  color: #9f9f9f;
  padding: 0px 20px 10px 20px;
}
.descriptionFamilyText {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  padding: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 0px;
  color: #9f9f9f;
  /* padding: 0px 20px 10px 20px; */
}

.pageTitleText {
  color: #245cc5;
  font: Roboto, sans-serif;
  font-weight: 500;
  font-size: 24px;
}

.declineButton {
  /* width: 173px; */
  /* height: 47px; */
  background: white;
  /* box-shadow: 0px 3px 6px #00000029; */
  /* border: 2px solid #ff4545; */
  /* border-radius: 30px; */
  opacity: 1;
  font: Roboto, sans-serif;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0;
  color: #cb2b2b;
  box-shadow: none;
  border: none;
  /* display: flex; */
  /* justify-content: center; */
  /* align-content: center; */
}

.declineButton:active {
  opacity: 0.5;
}

.declineButton:focus {
  outline: none;
}

button.declineButton:disabled,
button[disabled] {
  color: #989898;
}

.overflow {
  overflow: auto;
}

/* @media only screen and (min-width: 830px) { */
  .proposalDescription {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1280px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 40px 20px 50px 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
  }

  .descriptionHeader {
    display: flex;
    flex-direction: row;
  }

  .descriptionHeader {
    width: 100%;
  }

  .itemContainer {
    width: 100%;
    border: solid 1px #a5a1a1;
    padding: 10px;
    margin-bottom: 17px;
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .declineButton {
    width: 145px;
    height: 42px;
    background: #ff6565 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #ff4545;
    border-radius: 30px;
    opacity: 1;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0;
    color: #cb2b2b;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  button.declineButton:disabled,
  button[disabled] {
    border: 1px solid #b5b5b5;
    background-color: #d6d3d3;
    color: #989898;
  }

  .proposalDescriptionMenu {
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
/* } */
